import {
    DeepLinkData,
    HashedEmailResp,
    IGeoData,
    SignUpData,
    UserInfoResponse,
    UserUpdateData,
} from 'types/user/userApiInterface';

import Base from './Base';

class UserApi extends Base {
    async getUser<Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/');
    }

    async update<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.patch<Data, Response>('user/', payload);
    }

    async completeRegistration<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('user/complete-registration', payload);
    }

    async signUp<Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.public.post<Data, Response>('sign-up/', payload);
    }

    async deepLinks<Response = DeepLinkData>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/auth-deeplink');
    }

    async hashedEmail() {
        return await this.apiClient.private.get<HashedEmailResp>('front-hash');
    }

    async geoData<Response = IGeoData>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/geo-data');
    }

    async getSubscriptions() {
        return await this.apiClient.private.get<string>('user/subscription');
    }

    async cancelSubscription<Data = { payload: { external_id: string } }>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('solidgate/subscription/cancel', payload);
    }
}

export default UserApi;
