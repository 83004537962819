import { Provider } from 'wikr-core-analytics';

import * as actionTypes from './actionTypes';

import {
    DeepLinkData,
    IUpdateMailingPreferences,
    IUpdateUserInfoSaga,
    SignUpData,
    SubscriptionType,
    UserInfoResponse,
} from 'types/user/userApiInterface';

export function setIsPaid(status: boolean) {
    return {
        type: actionTypes.SET_IS_PAID,
        payload: status,
    } as const;
}

export function setUpdatedAtTime(time: Date) {
    return {
        type: actionTypes.SET_UPDATED_AT_TIME,
        payload: time,
    } as const;
}

export function signUp(data: SignUpData) {
    return {
        type: actionTypes.SIGN_UP,
        payload: data,
    } as const;
}

export function signUpSuccess(data: Partial<UserInfoResponse>) {
    return {
        type: actionTypes.SIGN_UP_SUCCESS,
        payload: data,
    } as const;
}

export function signUpFail(e: any) {
    return {
        type: actionTypes.SIGN_UP_FAIL,
        payload: e,
    } as const;
}

export function getDeepLinks() {
    return { type: actionTypes.GET_DEEP_LINK } as const;
}

export function getDeepLinksSuccess(link: DeepLinkData) {
    return { type: actionTypes.GET_DEEP_LINK_SUCCESS, payload: link } as const;
}

export function updateMailingPreferences(payload: IUpdateMailingPreferences) {
    return {
        type: actionTypes.UPDATE_MAILING_PREFERENCES,
        payload: payload,
    } as const;
}

export function updateUserInfo(payload: IUpdateUserInfoSaga) {
    return {
        type: actionTypes.UPDATE_INFO,
        payload: payload,
    } as const;
}

export function completeRegistration(payload: IUpdateUserInfoSaga) {
    return {
        type: actionTypes.COMPLETE_REGISTRATION,
        payload: payload,
    } as const;
}

export function updateUserInfoFail(error: unknown) {
    return {
        type: actionTypes.UPDATE_INFO_FAIL,
        payload: error,
    } as const;
}

export function setUserEmail(email: string) {
    return {
        type: actionTypes.SET_EMAIL,
        payload: email,
    } as const;
}

export function setGuide(id: string) {
    return {
        type: actionTypes.SET_GUIDE_ID,
        payload: id,
    } as const;
}

export function setToken(token: string) {
    return {
        type: actionTypes.SET_USER_TOKEN,
        payload: token,
    } as const;
}

export function setCountry(country: string) {
    return {
        type: actionTypes.SET_COUNTRY,
        payload: country,
    } as const;
}

export function setIsAutologinComplete(status: boolean) {
    return {
        type: actionTypes.SET_IS_AUTOLOGIN_COMPLETE,
        payload: status,
    } as const;
}

export function setPlatform(platform: string) {
    return {
        type: actionTypes.SET_PLATFORM,
        payload: platform,
    } as const;
}

export function enableIsLoading() {
    return {
        type: actionTypes.SET_IS_LOADING,
        payload: true,
    } as const;
}

export function disableIsLoading() {
    return {
        type: actionTypes.SET_IS_LOADING,
        payload: false,
    } as const;
}

export function setIsPersonalPlan(isPersonalPlan: boolean) {
    return {
        type: actionTypes.SET_IS_PERSONAL_PLAN,
        payload: isPersonalPlan,
    } as const;
}

export function setUserId(id: number) {
    Provider.setUserId(id).then((r) => r);

    return {
        type: actionTypes.SET_USER_ID,
        payload: id,
    } as const;
}

export function getSubscriptions() {
    return {
        type: actionTypes.GET_SUBSCRIPTIONS,
    } as const;
}

export function setSubscriptions(payload: SubscriptionType[]) {
    return {
        type: actionTypes.SET_SUBSCRIPTIONS,
        payload,
    } as const;
}

export function setSubscriptionsError(payload: boolean) {
    return {
        type: actionTypes.SET_SUBSCRIPTIONS_ERROR,
        payload,
    } as const;
}

export function cancelSubscription(payload: { external_id: string }) {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION,
        payload,
    } as const;
}
